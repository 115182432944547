
@font-face {
  font-family: 'karnakpro-condensed';
  src: url('karnakpro-condensedblack-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

:root{
  --background: white;
  --text: black;
  --borders: lightgray;
  --gray: #787c7e;
}

[data-darktheme=true]{
  --background: black;
  --text: white;
  --borders: grey;
  --gray: #434548;
}

.App {
  width: 100vw;
  height: 100vh;
  background-color: var(--background);

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

header{
  width: 100%;
  border-bottom: 1px solid var(--borders);
  color: var(--text);
  position: relative;

  display: flex;
  justify-content: center;
}

header div{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

h1{
  font-family: 'karnakpro-condensed', serif;
  font-size: 40px;
  font-weight: normal;
  transform: scale(1,.8);
  margin: 0;
}

header span{
  text-transform: uppercase;
  font-weight: bold;
  padding-top: 10px;
}

.switch-container{
  position: absolute;
  right: 10px;
  top: 15px;
}

.switch-container span{
  padding: 0;
  color: rgb(154, 154, 154);
}

.switch{
  position: relative;
  display: inline-block;
  width: 30px;
  height: 21px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #6baa64;
}

input:focus + .slider {
  box-shadow: 0 0 1px #6baa64;
}

input:checked + .slider:before {
  transform: translateX(10px);
}

.Board{
  display: grid;
  grid-template-rows: repeat(6, 60px);
  gap: 5px;
}

.board-row{
  display: grid;
  grid-template-columns: repeat(5, 55px);
  gap: 5px;
}

.inputs{
  border: 1px solid var(--borders);
  color: var(--text);
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  /* transition: transform 1s; */

  display: flex;
  align-items: center;
  justify-content: center;
}

.filled{
  border: 2px solid darkgray;
  animation: fill .1s;
}

.Keyboard{
  margin-top: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.key-rows{
  display: flex;
  gap: 5px;
}

.keys{
  background-color: var(--borders);
  color: var(--text);
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  min-width: 10px;
  padding: 20px 15px;
  border-radius: 3px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
}

.message{
  background-color: var(--text);
  color: var(--background);
  padding: 15px;
  margin: 10px;
  min-height: 20px;
  border-radius: 3px;
  font-weight: bold;
  opacity: 0;
  transition: opacity .2s ease-out;
}

.message.show{
  opacity: 100;
}

.board-row.shake{
  animation: shake .3s;
}

.popup-container{
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.527);
  z-index: 1;

  display: flex;
}

.popup{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--background);
  color: var(--text);
  border-radius: 5px;
  width: 500px;
  max-width: 90vw;
  height: max-content;
  padding: 10px 20px 30px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#close{
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-weight: bold;
}

.popup h3{
  margin: 20px 0 10px;
  text-transform: uppercase;
}

button{
  border: none;
  padding: 20px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
}

button:hover{
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}

.statSummary{
  display: flex;
  gap: 10px;
}

.statBox{
  max-width: 50px;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.statValue{
  font-size: 30px;
  font-weight: bold;
}

.statLabel{
  font-size: 12px;
}

.distribution{
  width: 70%;
  font-size: 14px;

display: flex;
flex-direction: column;
align-items: flex-start;
gap: 5px;
}

.chart{
  display: grid;
  grid-template-columns: 20px 1fr;
}

.chart span{
  padding: 2px 5px;
}

.bar{
  min-width: 10px;
  background-color: #787c7e;
  color: white;
  text-align: right;
  font-weight: bold;
}
/* 
.inputs:nth-child(1){
  transition-property: background-color;
  transition-delay: .5s;
}

.inputs:nth-child(2){
  transition-property: background-color;
  transition-delay: 1s;
}

.inputs:nth-child(3){
  transition-property: background-color;
  transition-delay: 1.5s;
}
.inputs:nth-child(4){
  transition-property: background-color;
  transition-delay: 2s;
}
.inputs:nth-child(5){
  transition-property: background-color;
  transition-delay: 2.5s;
}

.inputs:nth-child(1).colored{
  animation: flip .5s;
}

.inputs:nth-child(2).colored{
  animation: flip .5s ease-in-out 1s;
}


.inputs:nth-child(3).colored{
  animation: flip .5s ease-in-out 1.5s;
}


.inputs:nth-child(4).colored{
  animation: flip .5s ease-in-out 2s;
}


.inputs:nth-child(5).colored{
  animation: flip .5s ease-in-out 2.5s;
} */

.gray{
  background-color: var(--gray);
    border-color: var(--gray);
    color: white;
}

.yellow{
  background-color: #c8b458;
    border-color: #c8b458;
    color: white;
}

.green{
  background-color: #6baa64;
    border-color: #6baa64;
    color: white;
}

/* 
@keyframes flip {
  0% {transform: rotateX(30deg);}
  25%{transform: rotateX(60deg);}
  50%{transform: rotateX(90deg);}
  75%{transform: rotateX(60deg);}
  100%{transform: rotateX(30deg);}
} */

@keyframes shake {
  0% {transform: translate(0, 0);}
  20% {transform: translate(-4px, 0);}
  40% {transform: translate(4px, 0);}
  60% {transform: translate(-4px, 0);}
  80% {transform: translate(4px, 0);}
  100% {transform: translate(0, 0);}
}

@keyframes fill {
  0% {transform: scale(1.1);}
  100% {transform: scale(1);}
}

@media (max-width: 767px) {
  .keys{
    padding: 15px 10px;
  }
  .message{
    position: absolute;
    top: 50px;
  }
  header{
    margin-bottom: 20px;
  }
}